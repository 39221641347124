<template>
  <div class="bug-bounty-main">
    <PageBanner
      :firstword="servicesContent[$route.params.id].firstword"
      :keyword="servicesContent[$route.params.id].keyword"
      :secondword="servicesContent[$route.params.id].secondword"
      :img="'banner-bugbountymain.jpg'"
      :subtext="servicesContent[$route.params.id].subtitle"
    />
    <div class="image-text">
      <img src="../assets/img/video-bugbounty.svg" alt="" />
      <div class="text">
        <h4>{{ servicesContent[$route.params.id].detailTitle }}</h4>
        <p>
          {{ servicesContent[$route.params.id].detailText }}
        </p>
      </div>
    </div>
    <div class="carousel">
      <div class="swiper">
        <Swiper
          :slides-per-view="1"
          :centered-slides="true"
          :navigation="{ nextEl: '.next-slide', prevEl: '.prev-slide' }"
        >
          <Swiper-slide
            v-for="(slide, $s) in servicesContent[$route.params.id].slides"
            :key="$s"
          >
            <h1>{{ slide.title }}</h1>
            <p>{{ slide.text }}</p>
          </Swiper-slide>
          <div class="navigation">
            <button class="prev-slide">
              <i class="flaticon-up-arrow"></i>
            </button>
            <button class="next-slide">
              <i class="flaticon-up-arrow"></i>
            </button>
          </div>
        </Swiper>
      </div>
    </div>
    <div class="contac-us-footer">
      <ContactUsFooter :content="{ text: servicesContent[$route.params.id].footer, image: 'CTA-JAMA-1' }" />
    </div>
  </div>
</template>

<script>
import PageBanner from "@/components/page-banners.vue";
import store from "../store";
//Import swiper core
import SwiperCore, { Navigation } from "swiper";
import ContactUsFooter from "../components/contactus-footer.vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

SwiperCore.use([Navigation]);

export default {
  data() {
    return {
      swiperOption: {
        centeredSlides: true,
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    ContactUsFooter,
    PageBanner,
  },
  computed: {
    servicesContent() {
      return this.$store.state.content;
    },
  },
  beforeRouteEnter(to, from, next) {
    if (
      Object.prototype.hasOwnProperty.call(store.state.content, to.params.id)
    ) {
      next();
    } else {
      next("/error404");
    }
    next();
  },
  beforeRouteUpdate(to, from, next) {
    if (
      Object.prototype.hasOwnProperty.call(store.state.content, to.params.id)
    ) {
      next();
    } else {
      next("/error404");
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.bug-bounty-main {
  .image-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px;
    @media (max-width: 1280px) {
      width: 100%;
      flex-direction: column;
    }
    img {
      width: 625px;
      @media (max-width: 1280px) {
        width: 100%;
      }
    }
    .text {
      margin-left: 100px;
      @media (max-width: 1280px) {
        width: 100%;
        margin: 0;
      }
      h4 {
        font-size: 45px;
        color: #000033;
        margin: 0 0 30px 0;
      }
      p {
        width: 570px;
        color: #676777;
        @media (max-width: 1280px) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
  .carousel {
    height: 650px;
    background-color: #f9f9ff;
    @media (max-width: 1280px) {
      height: 600px;
      margin: 0;
    }
    .swiper {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      .swiper-container {
        width: 100%;
        position: relative;
        padding-bottom: 10px;
        .swiper-wrapper {
          .swiper-slide {
            width: 1280px;
            padding: 100px;
            text-align: center;
            display: flex;
            flex-direction: column;
            width: 800px;
            h1 {
              margin-bottom: 50px;
            }
            p {
              width: 800px;
              margin: auto;
            }
            @media (max-width: 1280px) {
              width: 100%;
              padding: 20px;
              p {
                width: 100%;
              }
            }
          }
        }
        .navigation {
          width: 100%;
          button {
            width: 125px;
            height: 50px;
            border-radius: 25px;
            margin: 0 15px;
            background-color: #222ac3;
            color: white;
            position: relative;
            border: none;
            i {
              right: 50px;
              position: absolute;
              top: 13px;
              &::before {
                margin: 0;
                font-size: 24px;
              }
            }
            &.prev-slide {
              i {
                transform: rotate(270deg);
              }
            }
            &.next-slide {
              i {
                transform: rotate(450deg);
              }
            }
            &.swiper-button-disabled {
              background-color: #a6a7c1;
              border: 1px solid #a6a7c1;
            }
          }
        }
      }
    }
  }
}
</style>
